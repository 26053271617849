const projects = [
    {
        name: `MadTofu22's Marvelous Mastermind`,
        path: `/project/mastermind`,
        image: `mastermind.png`,
        video: `https://www.youtube.com/embed/COGbjKvywSI`,
        link: `https://madtofus-marvelous-mastermind.herokuapp.com/#/home`,
        repo: `https://github.com/MadTofu22/madtofus-marvelous-mastermind`,
        synopsis: `A web app version of the code breaking board game Mastermind with leaderboards.`,
        description: `This project is the culmination of knowledge I learned in Tiers 1 and 2 at Prime Digital Academy. In this application User's are able to play the game Mastermind, create a profile, update their information, delete their profile, and view rankings on the leaderboards.`,
        technologies: [`HTML`, `CSS`, `Javascript`, `Express.js`, `Body-Parser.js`, `Postgreql`, `React`, `React Router`, `Redux`, `Passport`, `Node`,],
    },
    {
        name: `I Have a Project`,
        path: `/project/`,
        image: `ihap.png`,
        link: ``,
        video: `https://www.youtube.com/embed/658-OlkiTCQ`,
        repo: `https://github.com/MadTofu22/i-have-a-project`,
        synopsis: `Connecting managers to even out time and labor peaks/valleys.`,
        description: `This project was my first completed group project as a developer. At Prime Digital Academy, we are given the opportunity to work with a client and make their dreams come true by developing their app idea. Our client, a Prime alum, that wanted to create a SaaS prototype in an effort to get funding and create an enterprise level application for a targeted industry. We demonstrated this application in the nice of 3D Design, however I'm proud to say this application could be used in many other places, or even inter-departmentally with a Scrum Agile team.`,
        technologies: ['HTML', 'CSS', 'Javascript', 'React', 'Redux', 'Passport', 'Express', 'Postgres', 'SQL', 'GitHub', 'Google Chrome', 'Postico', 'Postman', 'DBeaver', 'FullCalendar.io', 'Email.js'],
    },
    {
        name: `Feedback Survey`,
        path: `/project/feedback`,
        image: `feedback_loop.png`,
        link: `https://madtofu22s-feedback-survey.herokuapp.com/#/`,
        repo: `https://github.com/MadTofu22/redux-feedback-loop`,
        synopsis: `A short survey app used to develope SQL skills and learn the Material-UI library.`,    
        description: `This simple survey allows a student to enter ratings and comments about their day. These ratings and comments are then viewable by an Admin to mark entries for review, sort through them, and/or delete entries.`,
        technologies: [`HTML`, `CSS`, `Javascript`, `jQuery`, `React`, `Node`, `Express`, `BodyParser`, `Material-UI`],
    },
    {
        name: `MadTofu22's To-Do App`,
        path: `/project/to-do`,
        image: `to_do_app.png`,
        link: `https://madtofu22s-task-list.herokuapp.com`,
        repo: `https://github.com/MadTofu22/weekend-sql-to-do-list`,
        synopsis: `This CRUD project allows the user to add or create tasks and store them to a database.`,
        description: `This CRUD application demonstrates my ability to develop REST APIs and update a database usign SQL. I also learned a good amount about conditional rendering while developing this application.`,
        technologies: [`HTML`, `CSS`, `Javascript`, `jQuery`, `Express.js`, `Body-Parser.js`, `Postgreql`, `React`, `React Router`, `Redux`, `Node`,],
    },
    {
        name: `MadTofu22's Marvelous Math Manipulator`,
        path: `/projects/math-manipulator`,
        image: `math_manipulator.png`,
        link: `https://madtofu22s-math-manipulator.herokuapp.com`,
        repo: `https://github.com/MadTofu22/jquery-server-side-calculator`,
        synopsis: `Learned how to connect a web app to an Express server with this simple calculator.`,
        description: `MadTofu22's Marvelous Math is a server side calculator designed to enchance my knowledge of creating web applications. This was also the first project I created that utilizes Express for a server.`,
        technologies: [`HTML`, `CSS`, `Javascript`, `jQuery`, `React`, `Node`, `Express`, `BodyParser`,],
    },
    // {
    //     name: ``,
    //     path: `/project/`,
    //     image: ``,
    //     link: ``,
    //     repo: ``,
    //     synopsis: ``,
    //     description: ``,
    //     technologies: [],
    // },
];
 
export default projects;